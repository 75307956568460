export class Colors {
  primary: string = '#0e7836';
  primary_dark: string = '#0e7836';
  secondary: string = '#492887';
  danger: string = '#f53d3d';
  warning: string = '#FFCC00';
  light: string = '#EFEFEF';
  gray: string = '#989aa2';
  dark: string = '#0C3235';
  black: string = '#000000';
  white: string = '#ffffff';
  primary_contrast: string = '#ffffff';
  toolbar = '#f8f8f8';
}
export class AppConfig {
  colors: Colors = new Colors();
  title: string = 'Electric Freeway';
  appID: string = '';
  appHost: string = 'app.electricfreeway.net';
  showTitle: boolean = false;
  defaultLang: string = 'en';
  appToken: string = '4f139d0e-b95c-441b-8519-304113c6856d';
  pushAppID: string = 'ebfdc8c7-f9bd-4dc8-8137-ab9487ba8f9c'; //2bc90d15-bf99-4b42-9ca8-3c6ee03c5e34
  toolbarLogoHeight: string = '1.6em';
  pushSenderID: string = '366913774201';
  root: string = 'https://cms.electricfreeway.net';
  readonly isWhitelabel: boolean = this.appToken ? true : false;
  stationIcon: StationIcon = { height: 44, width: 30 };
  availableLangs: string[] = ['nb', 'en', 'da', 'sv'];
  privacyPolicy: any = {
    nb: 'https://electricfreeway.no/privacy-policy',
    en: 'https://electricfreeway.no/privacy-policy'
  };
  termsAndConditions: any = {
    nb: 'https://electricfreeway.net/terms-and-conditions',
    en: 'https://electricfreeway.net/terms-and-conditions'
  };
  contact: any = {
    external: false,
    email: 'support@electricfreeway.no',
    phone: '',
    web: '',
    chat: '',
    customerService: {
      nb: 'https://www.electricfreeway.no/faq',
      en: 'https://www.electricfreeway.no/faq'
    }
  };
  constructor() {}
}

interface StationIcon {
  height: number;
  width: number;
}
